import { useEffect } from 'react';

import { Provider } from 'react-redux';
import { createMuiTheme, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import CssBaseline from '@material-ui/core/CssBaseline';

import store, { persistor } from '../store/store';

import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/styles.scss';
import { AppProps } from 'next/app';

const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      light: '#457f81',
      main: '#38686A',
      dark: '#244243',
      contrastText: '#fff',
    },
    secondary: {
      light: '#CDC6AE',
      main: '#CDC6AE',
      dark: '#b8b29d',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: [
      '"Inter"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles: Element = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <Provider store={ store }>
      <PersistGate loading={ null } persistor={ persistor }>
        <MuiThemeProvider theme={ theme }>
          <CssBaseline />
          <ToastContainer
            position={ 'bottom-center' }
            autoClose={ 5000 }
            closeButton={ true }
            hideProgressBar={ true }
            closeOnClick={ true }
            limit={ 3 }
          />
          <Component { ...pageProps } />
        </MuiThemeProvider>
      </PersistGate>
    </Provider>

  );
}

export default MyApp;